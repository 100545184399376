.user-menu__name {
    margin-left: -9px;
    font-weight: bold;
}

.user-menu__email {
    margin-left: -9px;
}

.menu a:not([href]) {
    color: #337ab7;
    height: 35px;
    text-decoration: none;
}

.menu a:not([href]):hover {
    color: #0056b3;
    text-decoration: none;
}

.menu-hamburguer-dados-pessoais{
    visibility: hidden;
    background: rgb(255 255 255);
    border: 1px solid rgb(247 247 247);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    position: absolute;
    z-index: 0;
    opacity: 0;
    padding: 0;
    right: auto;
    pointer-events: none;
    height: 10px;

}

.visibled-menu-hamburguer-dados-pessoais{
    transition: 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    z-index: 9994;
    pointer-events: initial;
}

.ul-menu-dados-pessoais:hover{
    background-color:#f2f2f2;
}

.ul-menu-dados-pessoais, .ul-menu-hamburguer-dados-pessoais a{
    cursor: pointer;
    text-decoration: none;
    color: unset;    
}

.btn-login-facebook{
    height: 5em;
    width: 18em;
}

.btn-login-google{
    height: 5em;
    width: 18em;
}