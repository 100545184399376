:root {
    --width-vitrine-imagem: 200px;
    --height-vitrine-imagem: 200px;
    --background-btn-voltar: #0069d9 ;
    --color-btn-voltar: #ffffff;
    --background-btn-voltar-hover: #0069d9;
    --color-btn-voltar-hover: #ffffff;
}

html, body {
    font-family: 'Roboto', sans-serif;
    color: var(--color-default);
    background-color: var(--background-area-container);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.text-decoration-line-through {
    text-decoration: line-through;
    color: var(--color-link-remover);
}

.item-indisponivel {
    background-color: #ea1d2c;
}

.remover-indisponivel {
    color: #ffffff !important;
}

.text-size-voltar-area-restrita {
    font-size: 15px !important;
}

.bg-danger button.close,
.bg-danger {
    color: var(--background-default);
}

#root {
    height: 100%;
    background-color: var(--background-default);
}

.display-none {
    display: none !important;
}

::selection {
    color: #fff;
    background: #3390FF;
}

/* Menu */
.btn-navigation:hover .btn-navigation__icon i {
    color: var(--color-icon-header-desktop);
    fill: var(--color-icon-header-desktop);
}

.card-menu-perfil {
    visibility: hidden;
     width: 322px;
     max-height: 80vh;
     overflow-x: hidden;
     overflow-y: auto;
     background: #fff;
     border: 1px solid #f7f7f7;
     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
     border-radius: 4px;
     position: absolute;
     z-index: 0;
     opacity: 0;
     padding: 30px 0px 0px 0px;
     right: auto;
     pointer-events: none;
}


.card-menu-perfil-aberto {
       transition: 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
       visibility: visible;
       opacity: 1;
       -webkit-transform: translateY(10px);
               transform: translateY(10px);
       z-index: 9994;
       pointer-events: initial;
}

/*Titulo*/
h1{
    font-family: 'Roboto', sans-serif;
    margin: 12px 0 12px 0;
    font-size: 22px;
    text-transform: uppercase;
    color: var(--color-texto);
    font-weight: normal;
}
/*Titulo*/


/*Table*/
table.table {
    font-family: 'Roboto', sans-serif;
}

table.table thead tr{
    border: none;
}

table.table thead tr th {
    color: var(--color-texto);
    font-weight: normal;
    text-transform: uppercase;
    font-size: 12px;
    padding: 2px 4px;
    border-bottom: solid 1px var(--color-table-input-border);
}

table.table tbody tr  {
    background: var(--background-table);
}

table.table tbody tr td {
    vertical-align: middle !important;
    padding: 8px 4px;
}
/*Table*/


/*Input*/
select.form-control, select.form-control:active,
input.form-control, input.form-control:active {
    margin-bottom: 10px;
    border: none;
    border-radius: 0;
    color: var(--color-texto);
    box-shadow: none;
    border-bottom: solid 1px var(--color-table-input-border);
}

select.form-control:focus,
input.form-control:focus {
    outline: 0;
    font-weight: bold;
    box-shadow: none;
    color: var(--color-texto);
    border-bottom: solid 2px var(--color-table-input-border);
}

.form-controll-erro select.form-control,
.form-controll-erro  select.form-control:focus,
.form-controll-erro input.form-control,
.form-controll-erro  input.form-control:focus,
.form-controll-erro label.form-check-label {
    color: var(--color-texto-erro);
}

.span-block {
    color: var(--color-texto);
    word-wrap: break-word;
}

.help-block {
    color: var(--color-texto-erro);
}

.help-block ul {
    margin: 0;
    padding: 0;
}

.help-block ul li {
    margin: 0;
    padding: 0;
    list-style: none;
}

.form-check-input {
    margin-left: -1.95rem !important;
}

.form-check {
    padding-left: 1.95rem;
}
/*Input*/

/*Button*/
.btn-primary {
    background-image: unset !important;
    background-color: #007bff !important;
    color: #fff !important;
    border-color: unset !important;
}

.btn-danger {
    background-image: unset !important;
    background-color: #ea1d2c !important;
    color: #fff !important;
    border-color: unset !important;
}

.btn-danger:hover {
    background-color: #c2121f !important;
    border-color: #c2121f !important;
}

.btn-primary:hover {
    background-color: #0069d9 !important;
    border-color: #0062cc !important;
}

button {
    border: none;
    color: var(--color-button);
    text-shadow: none;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    padding: 5px 15px;
    outline: 0;
    border-radius: 5px;
}

button:hover {    
    color: var(--color-button-hover);
    outline: 0;
}

.btn-warning:hover,
.btn-primary:hover,
.btn-success:hover {
    background-position: 0px -20px;
}

button.btnPrimary {
    background: none;
    color: var(--color-texto);
    border: solid var(--color-texto) 2px;
}

button.btnPrimary:hover {
    color: var(--color-button);
    background: var(--color-texto);
}

button.btnDefault {
    background: none;
    color: #2BBBAD;
    border: solid #2BBBAD 2px;
}

button.btnDefault:hover {
    color: #ffffff;
    background: #2BBBAD;
}

button.btnDanger {
    background: none;
    color: #d12525;
    border: solid #d12525 2px;
}

button.btnDanger:hover {
    color: #ffffff;
    background: #d12525;
}

button.btnCallback {
    background: none;
    color: #4A6A95;
    border: solid #4A6A95 2px;
}

button.btnCallback:hover {
    color: #ffffff;
    background: #4A6A95;
}

.btn-voltar {
    background-color: var(--background-btn-voltar) !important;
    color: var(--color-btn-voltar) !important;
}

.btn-voltar:hover {
    background-color: var(--background-btn-voltar-hover) !important;
    color: var(--color-btn-voltar-hover) !important;
}

table button {
    font-weight: normal;
    padding: 3px 0;
    margin: 3px;
    width: 30px !important;
    height: 30px !important;
    text-align: center;
    box-sizing: border-box;
}
/*Button*/

/* modal */
.modal-preload {
    background: var(--color-texto);
    z-index: 1100;
}

.modal-header {
    display: block !important;
    padding: 9px !important;
}

/* Toggle */
.toggle > input {
    -webkit-apparence: none !important;
    visibility: hidden !important;
    display: none !important;
}

.toggle > .check{
    position: relative;
    display: block;
    width: 40px;
    height:20px;
    background: #B2B2B2;
    cursor: pointer;
    border-radius:20px;
    overflow:hidden;
    transition: ease-in 0.5s;
}

.toggle > input:checked ~ .check{
    background: #B8EED4;    
}

.toggle > .check:before{
    content:'';
    position: absolute;
    top:2px;
    left:2px;
    background: #ECECEC;
    width:16px;
    height:16px;
    border-radius:50%;
    transition:0.5s;
}

.toggle > input:checked ~ .check:before{    
    transform: translateX(20px);
    background: green;
}

.toggle > input:checked ~ .texto::after{
    content: "A mimir u.u";
    position:absolute;
    color:#fff;
    margin-top:10px;
    margin-left:-50px;
    font-family: 'Roboto', sans-serif;
    z-index: 1;
    font-size:2em;    
}
/* Toggle */ 

/* Toast */ 
.toast-header {
    min-height: 35px;    
    height: auto;
}

.toast.show {
    min-width: 345px !important;
    max-width: 345px !important;
    min-height: 86px !important;    
}

.toast-header {        
    background-color: #E1E2E3;
    color: #333333 !important;
}

.toast-header strong {
    font-size: 14px;
    line-height: 21px;    
    font-weight: 700;
}

.toast-header small {
    font-size: 11px;
    line-height: 17px;    
    font-weight: 400;
}

.toast-header button span {
    font-size: 24px;
    line-height: 24px;
    color: #000000;
    font-weight: 700;
}

.toast-body {
    font-size: 14px;
    line-height: 21px;
    color: #212529;
    font-weight: 400;
    padding: 1.5rem .75rem;
}

.bg-warning,
.bg-warning .close {
    color: #ffffff;
}

@media (max-width: 720px) {        
    .btn-navigation-mobile{
        height: 40px;
    }    
}

@media screen and (min-width:650px) {
    .cardMenus {
        visibility: hidden;
        clear: both;
        float: left;
        margin: 10px auto 5px 20px;
        width: 28%;
        display: none;
    }
}