/* Carousel */
.showcase-product__thumb, 
.showcase-product__thumb-icon, 
.viewed-products__image-wrapper, 
.viewed-products__image-wrapper--selected {
    /* border-radius: 4px; */
    display: inline-block;
    width: 73px;
    height: 71px;
    padding: 1px;    
} 

.my-carousel {
    border: 1px solid #e6e4e6;
    border-radius: 4px;
    padding: 0;
    height: 256px;
}

.carousel-size {
    max-height: 243px;
    margin-top: 1px;
}

.carousel-list {
    border-right: 1px solid #e6e4e6;
    max-height: 243px;
    overflow: auto;
}

.carousel-list::-webkit-scrollbar {
    width: 5px; /* Largura da barra de rolagem */
 }
 
 .carousel-list::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Cor do fundo da barra de rolagem */
 }
 
 .carousel-list::-webkit-scrollbar-thumb {
    background-color: #888; /* Cor do "ponteiro" da barra de rolagem */
 }

.carousel-thumbnail {
    width: 67px;
    height: 66px;
}

.carousel-img {
    padding: 3px;
    height: 100%;
}

.carousel-img img {
    width: 230px;
    height: auto;
}

.my-carousel > *:first-child,
.my-carousel > *:last-child,
.my-carousel > .row > * {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-right: 0;
    padding-left: 0;
}

@media (max-width: 767px) {
    .my-carousel {
        height: 246px;
    }

    .carousel-list {
        position: relative;
        top: 186px;
        border-top: 1px solid #e6e4e6;
        height: 64px; 
        border-right: 0;
    }

    .carousel-img {
        position: relative;
        top: -64px;   
        padding: 0px;  
        margin: 10rem;   
    }   

    .carousel-img img {
        width: 172px;
        height: auto;
    }
    
    .carousel-thumbnail {
        width: 60px;
        height: 53px;
    }   

    .carousel-list u {
        padding: 2px;        
    }

    .carousel-list ul li {
        display: inline;
    }

    .carousel-list ul li {
        display: inline;
        padding: 20px 0px;
        margin: 1px;
    }

    .carousel-size {        
        padding: 0px;
        max-height: 49px;
        margin-top: 2px;
    }

    .showcase-product__thumb, 
    .showcase-product__thumb-icon, 
    .viewed-products__image-wrapper, 
    .viewed-products__image-wrapper--selected {        
        width: 67px;
        height: 56px;        
    } 
}


@media (min-width: 768px) and (max-width: 768px) {
    .my-carousel {
        height: 252px;
    }

    .carousel-img {
        padding: 10px;
        height: 100%;
    }

    .carousel-list {
        height: 250px;
    }    

    .showcase-product__thumb, 
    .showcase-product__thumb-icon, 
    .viewed-products__image-wrapper, 
    .viewed-products__image-wrapper--selected {        
        width: 67px;
        height: 56px;        
    } 
}