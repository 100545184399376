.pagamento-wrapper {
    margin-top: 1rem;
    padding: 0 2rem 0 2rem;
}

.fechamento-wrapper {
    border: 1px solid #dedede;
}

/* */
.checkout-content {
    padding: 2rem 30rem 2rem 30rem;
}

.checkout-content-panel-title {
    display: block;
    font-size: 2rem;
}

.checkout-content-painel-fechamento {
    order: 1;
}

.checkout-content-painel-endereco {
    order: 2;
}

.checkout-content-painel-pagamento {
    order: 3;
}

.checkout-content-painel-resumo {
    order: 4;
}

@media (max-width: 425px) {
    .checkout-content {
        padding: 1rem;
    }

    .checkout-content-panel-title {    
        display: none;
    }
    
    .checkout-content-painel-fechamento {
        order: 2;
    }
    
    .checkout-content-painel-endereco {
        order: 1;
    }
    
    .checkout-content-painel-pagamento {
        order: 4;
    }
    
    .checkout-content-painel-resumo {
        order: 3;
    }
    
    .checkout-content-panel-children > div > h4 {
        font-size: 1.6rem;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .checkout-content {
        padding: 1rem;
    }

    .checkout-content-panel-title {    
        display: block;
        font-size: 14px;
        text-align: center;
    }
    
    .checkout-content-panel-children > div > h4 {
        font-size: 1.6rem;
    }      
}

@media (min-width: 1440px) {
    .checkout-content-panel-title {    
        display: block;
        font-size: 16px;
        text-align: center;
    }
}
