/* container */
.content-container {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

/* main-layout */
.main-layout-content {
    height: 31em;
}

/* mini-content */
.mini-content {
    padding: 5px;
    height: 100%;    
}


.mini-content-form {
    height: 100%;
    min-height: 30em;    
}

.mini-content .form {
    padding: 5px 0.9em 10px 0.9em;    
}

.mini-content .mini-content-form {
    background:#fff;       
    width: 27%;  
    margin:0% auto auto auto;
    position: relative;
    border-radius: 7px;
    border: solid 2px #E7E6E7;
    z-index: 1;
}

/* container */
.container-overflow {
    overflow: auto;
}

.container-overflow.fullheight > #pagarNaEntrega > #modalidadePagamento, 
.container-overflow.fullheight > #pagoNoSite > #modalidadePagamento {
    margin-top: 15px;
}

.container-overflow.fullheight > #pagarNaEntrega #modalidadePagamento:first-child,
.container-overflow.fullheight > #pagoNoSite > #modalidadePagamento:first-child {
    margin-top: 20px;
}

@media (max-width: 320px) {
    .container-overflow {        
        max-height: 31em;
        overflow: auto;        
    }

    .container-overflow.fullheight {
        max-height: 36em;
    }    

    .container-overflow.fullheight > #pagarNaEntrega > #modalidadePagamento:first-child,
    .container-overflow.fullheight > #pagoNoSite > #modalidadePagamento:first-child {
        margin-top: 40px;
    }       

    .container-overflow.fullheight > #pagoNoSite {
        padding-top: 25px
    }
}

@media (min-width: 375px) and (max-width: 1023px) {
    .container-overflow {        
        max-height: 31em;
        overflow: auto;
    }

    .container-overflow.fullheight {
        max-height: 36em;
    }

    .container-overflow.fullheight > #pagarNaEntrega > #modalidadePagamento:first-child,
    .container-overflow.fullheight > #pagoNoSite > #modalidadePagamento:first-child {
        margin-top: 20px;
    }

    .container-overflow.fullheight > #pagoNoSite {
        padding-top: 10px
    }
}

@media (min-width: 360px) and (max-width: 360px) {
    .container-overflow {     
        max-height: 36em;  
        overflow: auto;
    }

    .container-overflow.fullheight {
        max-height: 41em;
    }

    .container-overflow.fullheight > #pagarNaEntrega > #modalidadePagamento:first-child,
    .container-overflow.fullheight > #pagoNoSite > #modalidadePagamento:first-child {
        margin-top: 40px;
    }

    .container-overflow.fullheight > #pagoNoSite {
        padding-top: 25px
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
    .container-overflow {        
        max-height: 28em;
        overflow: auto;
    }

    .container-overflow.fullheight {
        max-height: 33em;
    }

    .container-overflow.fullheight > #pagoNoSite {
        padding-top: 5px
    }
}

@media only screen and (min-width: 1439px) {
    .container-overflow {        
        max-height: 31em;
        overflow: auto;
    }

    .container-overflow.fullheight {
        max-height: 36em;
    }

    .container-overflow.fullheight > #pagoNoSite {
        padding-top: 5px
    }
}

/* Perfil */
.fotoPerfil {
    width: 8em;
}

/* Menu */
.checkout-context-card__maximized.cardMenus {
    background-color: #ffffff;
    height: 100%;    
    border-bottom: 1px solid #E6E4E6;
    margin-top: 0px;
}

/* Fontes */ 
.text-font-default {
    font-size: 0.997856em;
}

.text-font-16px {
    font-size: 16px
}

.text-font-14px {
    font-size: 14px
}

.text-button-opcao-pagamento {
    font-size: 12px !important;
    line-height: 17px !important;
    font-weight: 400 !important;
    color: #d12525 !important;
}

.header-modal-texto-1,
.header-modal-texto-2 {
    margin-top: 0px;
    font-size: 16px;
    line-height: 14px;
    font-weight: bold;
    color: var(--color-button);
    padding-left: 8px;
}

.header-modal-texto-2 {
    font-weight: 500;
}

/* lista box */
.lista-main {
    position: relative;
    margin-bottom: 104px;
    padding: 0px 0px 0px 0px;
}

.lista-box {    
    box-shadow: inset 0 -1px 0 #dcdcdc
}

.lista-container {    
    margin-top: -2px;
    border: 1px solid #e6e4e6;
    background-color: #ffffff !important;    
    padding: 0px
}

/* nav */
.nav-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    min-height: 52px;
    height: 52px;
    z-index: 9997; 
}

/* icon */
.icon-arrow {
    font-size: 25px;
    line-height: 15px;
    color: #3f3e3e;
    font-weight: bold;
}

/* buttonescolher */
.button-escolher {
    position: relative;
    display: block;
    font-weight: normal;
    /* height: 60px; */
    height: auto;
    justify-content: space-between;
    margin: 0;
    width: 100%;
    font-size: 16px;
    text-align: left;
    text-transform: none;
    padding: 10px 0px;
    background-color: #ffffff;    
}

.button-escolher::after {
    content: '';
    width: calc(100% - 40px);
    height: 0;
    display: block;
    background: transparent;
    position: absolute;
    left: 20px;
    bottom: 0;
    border-top: 1px solid #f5f0eb; 
}

.button-escolher span {
    color: #3f3e3e;
    font-weight: 300;
    font-size: 16px !important;
    line-height: 22px !important;
}

.button-escolher span.opcao-entrega-info__action.escolher {
    line-height: 42px !important;
}

.button-escolher img,
.button-escolher.pagamento img {
    display: inline-block;
    margin-right: 5px;
    width: 24px;
    position: relative;
    top: -1px; 
}

.button-escolher.pagamento img {
    width: 40px;
}

.button-modalidades-pagamento {
    border: 1px solid #e6e4e6;
    border-radius: 5px;
    width: 100%;
    background-color: #ffffff;
    color: #333333;                                         
    height: 100%;
    padding: 5px 10px;    
}

.button-modalidades-pagamento:hover {
    background-color: #D7ECDF;
    color: #333333;
}

.text-subtitulo {
    margin-top: 0px;
    color: #d12525;
    font-size: 12px
}

/* Modal */
.modal-body {
    position: relative;
    flex: 1 1 auto;    
    padding: 5px 10px 10px 10px !important;
}

@media (min-width: 768px) {
    .modal-pagamento .modal-dialog {
        max-width: 50% !important;        
    }
} 

.context-card__maximized .context-card__content {
    height: calc(100% - 52px);
    overflow: hidden; 
}

/* infinite scroll */
.infinite-scroll-component__outerdiv {
    width: 100%
}

/* fixed-scroll */
.fixed-scroll-bottom {    
    --height-fixed-scroll-bottom: 67vh;
}

/* Link */
a:not([href]) {
    color: #337ab7;   
    height: 50px;
    text-decoration: none;
}
  
a:not([href]):hover {
    color: #0056b3;
    text-decoration: none;
}

/* DesktopMenu */ 
.responsive-header {
    height: auto !important;
}

.floating-box__label {    
    font-size: 14px;
    line-height: 22px;
    color: (51,51,51,0.9) !important;
    font-weight: 400;
}

/* my footer */
.my-footer {
  position: fixed;
  bottom: 0;
  left: 6px;
  background-color: #f1f1f1;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  z-index: 9999;
  text-align: center;
  padding: 10px;
  justify-content: center;
  align-items: center;  
}

.my-footer > div > p {
  margin: 0px !important;
  margin-top: 0px !important;
}

/*-----start-responsive-design------*/
@media (min-width: 320px) and (max-width: 767px) {
    .mini-content-form.semborda {
        border: 0px;
    } 

    .my-footer {
      display: none;
    }
}

@media (min-width: 320px) and (max-width: 768px) {       
    .content-container {
        max-height: 100vh;
    }
    
    .my-footer {
      display: none;
    }
}

@media (max-width:768px) {        
    .content-container {
        max-height: 100vh;
    }

    .my-footer {
      display: none;
    }
}

@media (max-width:425px){     
    .mini-content .mini-content-form  {
        width: 100%;
    }
    
    .my-footer {
      display: none;
    }
}

@media (min-width:426px) and (max-width:1024px) {    
    .mini-content .mini-content-form  {
        width: 41%;
    }
    
    .my-footer {
      display: none;
    }   
}

@media (min-height: 538px) {
    .fixed-scroll-bottom {            
        --height-fixed-scroll-bottom: 61vh;
    }
}

@media (min-height: 597px) {
    .fixed-scroll-bottom {            
        --height-fixed-scroll-bottom: 57vh;
    }
}

@media (min-height: 640px) {
    .fixed-scroll-bottom {            
        --height-fixed-scroll-bottom: 66vh;
    }
}

@media (min-height: 823px) {
    .fixed-scroll-bottom {            
        --height-fixed-scroll-bottom: 74vh;
    }
}

@media (min-height: 1076px) {
    .fixed-scroll-bottom {            
        --height-fixed-scroll-bottom: 66vh;
    }
}
