@-webkit-keyframes glowRed {
    from {
        -webkit-box-shadow: rgba(255, 0, 0, 0) 0 0 0;
    }
    50% {
        -webkit-box-shadow: rgba(255, 0, 0, 1) 0 0 30px 5px;
    }
    to {
        -webkit-box-shadow: rgba(255, 0, 0, 0) 0 0 0;
    }
}

@-moz-keyframes glowRed {
    from {
        -moz-box-shadow: rgba(255, 0, 0, 0) 0 0 0;
    }
    50% {
        -moz-box-shadow: rgba(255, 0, 0, 1) 0 0 30px 5px;
    }
    to {
        -moz-box-shadow: rgba(255, 0, 0, 0) 0 0 0;
    }
}

.offline-sistema {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 50px;
    overflow-y: auto;
    color: #CCC !important;        
    z-index: 999999  
}

.offline-site,
.offline-sistema {
    padding: 137px;
    background-color: var(--background-thema);
}

#offline-txt {
    font: 60px Arial, sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    vertical-align: middle;
    -webkit-text-stroke: 0;
    -moz-text-stroke: 0;
    color: transparent;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background: #ffffff !important;
    color: red !important;
    text-shadow: rgba(255,255,255,0.5) 0 5px 6px, rgba(255,255,255,0.4) 1px 3px 3px;
    border: 2px solid black;
    border-radius: 25px;
    padding: 25px;
}

#offline-txt div {
    display: inline-block;
    background-color: orange;
    width: 40px;
    height: 40px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin-left: 10px;
    -webkit-animation-duration: 2.5s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-duration: 2.5s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -o-animation-duration: 2.5s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

#offline-txt.disconnected {    
    background-color: rgba(55, 0, 0, 1);
    border-color: red;
    -webkit-box-shadow: 0 0 50px 0 #bbb;
    -moz-box-shadow: 0 0 50px 0 #bbb;
    -ms-box-shadow: 0 0 50px 0 #bbb;
    -o-box-shadow: 0 0 50px 0 #bbb;
    box-shadow: 0 0 50px 0 #bbb;
}

#offline-txt.disconnected div {    
    background-color: red;
    -webkit-box-shadow: rgba(255, 0, 0, 0.5) 0px 0px 5px;
    -moz-box-shadow: rgba(255, 0, 0, 0.5) 0px 0px 5px;
    -ms-box-shadow: rgba(255, 0, 0, 0.5) 0px 0px 5px;
    -o-box-shadow: rgba(255, 0, 0, 0.5) 0px 0px 5px;
    box-shadow: rgba(255, 0, 0, 0.5) 0px 0px 5px;
    -webkit-animation-name: glowRed;
    -moz-animation-name: glowRed;
}

p {
    margin-top: 2em;
}

@media (max-width: 720px) {
    .offline-site,
    .offline-sistema {
        padding: 20px;
        padding-top: 153px;
    }

    #offline-txt {
        font: 35px Arial, sans-serif;
    }

    #offline-txt div {
        width: 28px;
        height: 28px;
    }
}