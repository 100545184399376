.logo {
  width: 100%;
}

.login .btn-group-lg > .btn,
.btn-lg {
  width: 100%;
}

.login-mobile-superwide {
  height: 100%;
  min-height: 30em;
}

.login .logo {
  height: auto !important;
  border-bottom: solid 1px #4a6a95;
}

.login h1,
.login h4 {
  font-family: "Roboto", sans-serif;
  margin: 10px 0 15px 0;
  font-weight: normal;
  font-size: 16px;
}

.login h1 {
  text-transform: uppercase;
  color: #4a6a95;
}

.login h5 {
  color: #4a6a95;
  font-weight: bold;
}

.login h4 {
  color: #999999;
}

.login .container {
  margin-top: 0;
}

.login .form {
  padding: 5px 0.9em 10px 0.9em;
}

.login label {
  color: #4a6a95;
  margin-bottom: 0;
}

.login > .fa-user,
.login .text-size {
  font-size: 18px !important;
}

.login #btnEntrar .text-size {
  margin-left: 10px;
}

.login .row {
  margin-top: 3px;
}

.login .row-title {
  margin-top: 13px;
}

.login .input-lg {
  margin-top: 0;
  height: 35px;
}

.login .btn-success:hover {
  background-color: #46a049 !important;
}

.login .background-senha {
  content: "\00f06e";
  /* fa fa-eye */
}

/*-----start-responsive-design------*/
@media (max-width: 1024px) {
  /* .login .logo {
        height: 100px !important;
    } */

  .login h6 {
    font-size: 11px;
  }
}

@media (max-width: 768px) {
  .login h6 {
    font-size: 11px;
  }
}

@media (max-width: 640px) {
  .login h6 {
    font-size: 11px;
  }
}

@media (max-width: 480px) {
  .login h6 {
    font-size: 11px;
  }
}

@media (max-width: 320px) {
  .login h6 {
    font-size: 11px;
  }

  .login > .fa-user,
  .login .text-size {
    font-size: 15px !important;
  }
}
.width100 {
  width: 100%;
}

.icon-google {
  background-color: rgb(255 255 255);
  font-size: 2.5em !important;
  border-radius: 50% !important;
  border: 1px solid #e6e4e6;
  height: 45px;
  width: 45px;
}

.kep-login-facebook.medium .fa-facebook-square {
  height: 25px !important;
  margin-left: -14px;
  margin-top: -5px;
}

.color-google-icon {
  color: gray;
}
