/* Credit Card */
.rccs {
    width: 100% !important;    
}

.rccs__card {
    width: auto !important;
    height: 153.97px;
}

.rccs__card--visa .rccs__issuer, .rccs__card--visaelectron .rccs__issuer {
    background-size: 60.35%;
    margin-top: 5px;
}

.rccs__number {
    font-size: 17px;
}

.rccs__name {
    font-size: 14px;
}

.rccs__expiry__value {
    font-size: 13px; 
}

.label__moeda {
    font-size: 48px;
    text-align: center;    
}

@media (max-width:768px) { 
    .label__moeda {
        font-size: 28px;
    }
}