/* lista */

.cupons ul {
  padding-left: 30px;
}
.cupom-valido {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 100%;
  height: 100%;
  background-color: rgba(5, 126, 5, 0.911);
  border: 0.5rem dotted;
  border-radius: 2rem;
  border-color: black;
  margin-top: -3%;
}
.cupom-invalido {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 100%;
  height: 100%;
  background-color: rgb(82, 82, 82);
  border: 0.5rem dotted;
  border-radius: 2rem;
  border-color: black;
  margin-top: -3%;
}
.cupomsemi {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 100%;
  height: 100%;
  background-color: rgba(105, 253, 36, 0.863);
  border: 0.5rem dotted;
  border-radius: 2rem;
  border-color: black;
  margin-top: -3%;
}

.saldo {
  margin-left: auto;
  margin-right: auto;
}
.saldo h1 {
  /* flex-direction: column; */
  text-align: end;
  margin-right: 3rem;
  text-decoration: none;
  color: black;
}

.gerados h1 {
  /* flex-direction: column; */
  text-align: end;
  font-size: small;
  margin-right: 3rem;
  text-decoration: none;
  color: black;
}
.containersaldos {
  flex-direction: column;
}

.tituloTabela {
  display: fixed;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 54px;
  height: 54px;
  padding: 5px;
}
.valorcupom {
  text-align: center;
  font-size: 2vw;
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
  background-color: #0057b300;
  width: 100%;
}

.saldoTotal {
  font-size: 3vw;
}
.geradoTotal {
  font-size: 2vw;
}
.cupons a:not([href]) {
  color: #000000 !important;
  height: 50px;
  text-decoration: none;
}

.cupons a:not([href]):hover {
  color: #000000 !important;
  text-decoration: none;
}
.valorResgatado {
  text-align: end;
}
.linkBotao {
  width: -webkit-fill-available;
  border-color: black;
  margin: 50vw;
}
.linhatracejada {
  visibility: hidden;
}
.listaCupom {
  margin-top: 1.5vw;
}

@media (max-width: 768px) {
  .valorcupom {
    font-size: 40% !important;
    max-width: 90%;
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
    background-color: #0057b300;
    width: 100%;
    font-stretch: 9;
    display: block;
    word-wrap: break-word;
  }

  .cupom-valido {
    align-items: flex;
    display: flex;
    justify-content: flex-start;
    text-align: start;
    max-width: 100% !important;
    height: 100%;
    background-color: rgba(5, 126, 5, 0.911);
    border: 4px dotted;
    border-color: #000000;
    border-radius: 2rem;
    padding-right: 3%;
    padding-left: 3%;
  }
  .cupom-invalido {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    text-align: center;
    max-width: 100% !important;
    height: 100%;
    background-color: rgb(82, 82, 82);
    border: 4px dotted;
    border-color: #000000;
    border-radius: 2rem;
    padding-right: 3%;
    padding-left: 3%;
  }
  .cupomsemi {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    text-align: start;
    max-width: 100% !important;
    height: 100%;
    background-color: rgba(105, 253, 36, 0.863);
    border: 4px dotted;
    border-color: #000000;
    border-radius: 2rem;
    padding-right: 3%;
    padding-left: 3%;
  }
  .Saldodisponivel {
    font-size: 6.5vw;
  }
  .saldoTotal {
    font-size: 100%;
  }
  .geradoTotal {
    font-size: 4vw;
  }
  .linhatracejada {
    visibility: visible;
    border-bottom: dashed none none none 0.5rem;
    border-color: #000000 !important;
  }
  .blockcupom {
    width: 55%;
    display: flex;
  }
  .blockvalor {
    width: 45%;
  }
  .fixedend {
    position: unset;
  }
  .psaldo {
    margin: 0;
    padding: 0;
    word-wrap: break-word;
    font-size: x-large;
  }
  .listaCupom {
    margin-top: 1.5vw;
    padding-bottom: 10vw;
  }
}
