.orderStatus {
    display: flow-root;    
    margin-bottom: 20px;
}

.btnStatusWaitingPayment {
  background-color: #DAA520;  
  opacity: 0.8;
  pointer-events: none;
}

.btnStatusDeniedPayment {
  background-color: black;  
  color: #ffffff;
  opacity: 0.8;
  pointer-events: none;
}

.color-black {
  color: #000000 !important;
}

.btnStatusOrderPlaced{
    background-color: orangered;
    color: #ffffff;
    opacity: 0.8;
    pointer-events: none;
}

.btnStatusOrderConfirmed{
    background-color: blue;
    color: #ffffff;
    opacity: 0.8;
    pointer-events: none;
}

.btnStatusInSeparation{
    background-color: #8B008B;
    color: #ffffff;
    opacity: 0.8;
    pointer-events: none;

}

.btnStatusTaxDocumentIssued {
    background-color: #DAA520;    
    color: #000000;
    opacity: 0.8;
    pointer-events: none;
}

.btnStatusTaxDocumentIssued .btn__label {
    color: #000000 !important;
}

.btnStatusOnCarriage{
    background-color: #008B8B;
    color: #ffffff;
    opacity: 0.8;
    pointer-events: none;
}

.btnStatusDelivered{
    background-color: #2dc257;
    color: #ffffff;
    opacity: 0.8;
    pointer-events: none;
}

.btnStatusOrderCanceled{
    background-color: red;
    color: #ffffff;
    opacity: 0.8;
    pointer-events: none;
}

.data-pedido{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 54px;
    height: 54px;
    padding: 5px;
    background-color: #f2f2f2;    
}

.textArea{
    height:100px;
    resize: none;    
}

@media (min-width: 992px) {
    .btnStatus{
        left: 28%;
        font-size: 80%;
        padding-left: 5%;
        pointer-events: none;
    }

    .btnStatus span{
        font-size: 78%;
    }

    .btnStatusDisabled{
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .btnStatus{
        width: 100% !important;
        left: 0%;
        font-size: 80%;
        padding-left: 11%;
        pointer-events: none;
    }

    .btnStatus span{
        font-size: 78%;
    }

    .btnStatusDisabled{
        display: none !important;
    }
}


@media (max-width: 767px) {
    .btnStatus{
        width: 43% !important;
        left: 15%;
        font-size: 85%;
        padding-left: 6%;
        pointer-events: none;
    }

    .btnStatus span{
        font-size: 88%;
    }

    .btnStatusDisabled{
        display: none !important;
    }
}
