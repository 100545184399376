.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    /* border-bottom: none; */
    border-top: none;
    z-index: 1025;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
    height: 208px;
    max-height: 208px;
    overflow-y: scroll;
    background-color: #ffffff;
}

.autocomplete-items li {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
    color: #000000;
}

.autocomplete-items li:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
}

::-webkit-input-placeholder,
:-moz-placeholder,
:-ms-input-placeholder { 
    color: red
}

.search-input__field::-webkit-input-placeholder {
    font-size: 14px; 
}

.search-input__field:-ms-input-placeholder {
    font-size: 14px; 
}

.search-input__field::-ms-input-placeholder {
    font-size: 14px; 
}

.search-input__field::placeholder {
    font-size: 14px; 
}